import { Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IdPortalIcon, KycSetupIcon, OverviewIcon } from './sideBarIcon';
//import { getStoredUser } from "../../pages/auth/user-storage";

const { SubMenu } = Menu;
interface Step1Props {
    dashboardSidebar: Boolean;
    showMenu: () => void;
}

const SideBar: React.FC<Step1Props> = ({ dashboardSidebar, showMenu }) => {
    // const [activeMenu, setActiveMenu] = useState('');
    const location = useLocation();

    // const generalStyle: any = {
    //     display: 'grid',
    //     gap: '1rem',
    //     background: '#F2F2F2',

    //     height: 50,
    // };

    // const subMenuStyle: any = {
    //     background: '#FEF5D7',
    //     borderRadius: '4px',
    //     // fontFamily: "Poppins",
    //     // fontStyle: "normal",
    //     // fontWeight: 500,
    //     // fontSize: "14px",
    //     // lineHight: "21px",
    //     // color: "#061B01",
    // };

    const currentPath = location.pathname;

    const getParentMenuKey = (path: string) => {
        if (path.startsWith('/dashbaord')) {
            return 'dashboard';
        } else {
            return 'dashboard'; // default parent menu key
        }
    };

    // Determine the parent menu key for the current route
    const currentParentMenuKey = getParentMenuKey(currentPath);

    // Set the active key and default selected keys
    const [activeKey, setActiveKey] = useState<string>(currentPath);
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([currentParentMenuKey]);

    const handleClick = (e: any) => {
        if (e.length !== 0) {
            setActiveKey(e);
        }
    };

    useEffect(() => {
        // Set the default selected keys based on the parent menu key
        setDefaultSelectedKeys([currentParentMenuKey]);
    }, [currentParentMenuKey]);

    const closeSidebar = () => {
        if (dashboardSidebar) {
            showMenu();
        }
    };

    const activeRouteClass = 'text-primary bg-blue-200';
    const routeNameClass = 'ms-2 text-navy-blue';

    return (
        <div className={` sidebarMain ${dashboardSidebar ? ' sidebarMainActivated' : ''}`}>
            <nav>
                <span className="bg-navy-blue px-3 py-2 m-auto rounded fs-8">VOXEPAY</span>
                <Menu
                    mode="inline"
                    // style={generalStyle}
                    // defaultOpenKeys={[activeKey]}
                    onOpenChange={handleClick}
                    defaultSelectedKeys={defaultSelectedKeys}
                    defaultOpenKeys={[currentParentMenuKey]}
                    className="sidebarHeading"
                >
                    <Menu.Item
                        key="/dashboard"
                        className={`menu-item ${currentPath.startsWith('/dashboard') ? activeRouteClass : ''}`}
                    >
                        <Link className="ps-0" onClick={closeSidebar} to="/dashboard">
                            {OverviewIcon()}
                            <span className={`${routeNameClass}`}>Overview</span>
                        </Link>
                    </Menu.Item>

                    <Menu.Item
                        key="/kycSetup"
                        className={`menu-item ${currentPath.startsWith('/kycSetup') ? activeRouteClass : ''}`}
                    >
                        <Link className="ps-0" onClick={closeSidebar} to="/kycSetup">
                            {KycSetupIcon()}
                            <span className={`${routeNameClass}`}> KYC Requirement Setup</span>
                        </Link>
                    </Menu.Item>

                    <Menu.Item
                        key="/kycManagement"
                        className={`menu-item ${currentPath.startsWith('/kycManagement') ? activeRouteClass : ''}`}
                    >
                        <Link className="ps-0" onClick={closeSidebar} to="/kycManagement">
                            {KycSetupIcon()}
                            <span className={`${routeNameClass}`}> KYC User Screen</span>
                        </Link>
                    </Menu.Item>

                    <Menu.Item
                        key="/idPortal"
                        className={`menu-item ${currentPath.startsWith('/idPortal') ? activeRouteClass : ''}`}
                    >
                        <Link className="ps-0" onClick={closeSidebar} to="/idPortal">
                            {IdPortalIcon()}
                            <span className={`${routeNameClass}`}> ID Verification Portal</span>
                        </Link>
                    </Menu.Item>
                </Menu>
            </nav>
        </div>
    );
};

export default SideBar;
