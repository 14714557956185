import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { errorHandlerFn } from 'utils';
//import { createKycSetupType } from 'interface/kycSetupInterface';
import { axiosService } from './axiosService';

export function useGetEntity(): any {
    async function getEntity(): Promise<any> {
        const { data }: AxiosResponse<any> = await axiosService.get(`/entity-types?page=0&size=0`);
        return data.data;
    }

    return useMutation(() => getEntity(), {
        onSuccess: (res) => {},
        onError: (err: any) => {
            errorHandlerFn(err);
        },
    });
}

export function useGetEntityById(): any {
    async function getEntityById(id:string): Promise<any> {
        const { data }: AxiosResponse<any> = await axiosService.get(`/entity-types/${id}`);
        return data.data;
    }

    return useMutation((id:string) => getEntityById(id), {
        onSuccess: (res) => {},
        onError: (err: any) => {
            errorHandlerFn(err);
        },
    });
}

export function useGetFieldType(): any {
    async function getFieldType(): Promise<any> {
        const { data }: AxiosResponse<any> = await axiosService.get(`/field-types`);
        return data.data;
    }

    return useMutation(() => getFieldType(), {
        onSuccess: (res) => {},
        onError: (err: any) => {
            errorHandlerFn(err);
        },
    });
}

export function useCreateTemplate(): any {
    async function createTemplate(values: any): Promise<any> {
        const { data }: AxiosResponse<any> = await axiosService.post(`/view-templates/save`, values);
        return data;
    }
    return useMutation((data: any) => createTemplate(data), {
        onSuccess: (res) => {},
        onError: (err: any) => {
            errorHandlerFn(err);
        },
    });
}

export function useUpdateTemplate(): any {
    async function createTemplate(values: any): Promise<any> {
        const { data }: AxiosResponse<any> = await axiosService.put(`/entity-types/${values.entityTypeId}`, values.sections);
        return data;
    }
    return useMutation((data: any) => createTemplate(data), {
        onSuccess: (res) => {},
        onError: (err: any) => {
            errorHandlerFn(err);
        },
    });
}


export function useSaveForm(): any {
    async function createTemplate(values: any): Promise<any> {
        const { data }: AxiosResponse<any> = await axiosService.post(`/entity`, values);
        return data;
    }
    return useMutation((data: any) => createTemplate(data), {
        onSuccess: (res) => {},
        onError: (err: any) => {
            errorHandlerFn(err);
        },
    });
}

export function useGetByEntityCode(): any {
    async function getEntityById(id:string): Promise<any> {
        const { data }: AxiosResponse<any> = await axiosService.get(`/entity/submitted-items/${id}`);
        return data.data;
    }

    return useMutation((id:string) => getEntityById(id), {
        onSuccess: (res) => {},
        onError: (err: any) => {
            //errorHandlerFn(err);
        },
    });
}

